.dialog-title-with-warning {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1.25rem 1fr;

  &::before {
    background-image: url(../../src/assets/Illustrations/warning-triangle.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    content: '';
  }
}

@import '../../scss/imports';

.subheader {
  margin: rem(24px 0 20px 0);

  .subheader-label {
    font-family: $font-demi;
    font-size: rem(24px);
    margin: 0;
  }

  .subheader-sub-text {
    font-size: rem(18px);
    margin: rem(8px 0 0 0);
  }
}

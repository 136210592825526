@import '../../scss/imports';

.create-vips {
  margin-bottom: rem(84px);

  .md-divider {
    margin-bottom: rem(8px);
  }

  .md-text-field-message-container {
    padding-right: rem(8px);
  }

  .names {
    margin-top: rem(20px);

    .title-name {
      font-family: $font-demi;
      font-size: rem(18px);
    }

    .name-fields {
      button {
        color: #AAA;
        display: none;
        transform: translate3d(-6px, -6px, 0);
      }

      .md-text-field.md-text {
        font-size: rem(16px);
      }

      &:nth-child(n+2) {
        .last:hover {
          button {
            display: inline-block;
          }
        }
      }

      .md-text-field.md-text {
        font-size: rem(16px);
      }
    }
  }

  .lots-title {
    margin-top: rem(32px);

    .title-lots {
      font-family: $font-demi;
      font-size: rem(18px);
    }

    .subtitle-lots {
      font-size: rem(18px);
    }
  }

  #duplicates-modal {
    .duplicates {
      padding-left: rem(20px);
      margin-bottom: 0;
    }
  }
}

@import '../../scss/imports';

.item-list-table {
  border-collapse: separate;
  border-spacing: rem(0 8px);
  margin-bottom: rem(84px);
  width: 100%;

  th,
  td {
    text-align: left;
  }

  .table-columns-4 {
    padding-bottom: rem(18px);
    padding-top: 0;
    width: 23%;
  }

  .table-columns-4:nth-of-type(2) {
    width: 36%;
  }

  .table-columns-3 {
    width: 40%;
  }

  th:last-of-type {
    width: 16%;
  }

  th:first-of-type,
  td:first-of-type {
    i {
      color: #DADADA;
      margin: rem(10px 12px);
    }
  }

  thead {
    text-align: left;
    text-transform: capitalize;

    th {
      padding: rem(8px 0);
      font-size: 18px;

      &.sortable {
        cursor: pointer;
      }
    }

    .th-item {
      align-items: center;
      display: flex;

      .th-icon {
        margin: rem(14px 8px 10px 8px);
        padding: 0;
        width: rem(10px);
      }
    }
  }

  tbody {
    tr {
      background: $white;
      border-radius: rem(4px);
      cursor: pointer;
      height: rem(48px);
    }

    td {
      .lots {
        display: flex;
        justify-content: flex-start;

        .tooltip-container {
          line-height: rem(12px);
          margin-left: rem(8px);
          width: rem(20px);
        }
      }

      .md-selection-control-container {
        width: rem(48px);
      }

      .td-checkbox {
        width: rem(48px);
      }
    }

    td:first-child {
      border-bottom-left-radius: rem(4px);
      border-left: 0;
      border-top-left-radius: rem(4px);
    }

    td:last-child {
      border-bottom-right-radius: rem(4px);
      border-right: 0;
      border-top-right-radius: rem(4px);
      padding-right: rem(24px);
    }

    td:nth-child(2) {
      font-family: $font-demi;
    }

    .lot-tooltip {
      height: rem(14px);
      margin: rem(4px);
    }

    .selected {
      background: rgba($primary-color, .11);
      box-shadow: rem(0 0 0 2px) #269CFC inset;

      i {
        color: #269CFC;
      }
    }

    .duplicate {
      background: rgba(255, 0, 0, 0.08);
    }

    .duplicate-selected {
      box-shadow: rem(0 0 0 2px) #FF0000 inset;

      i {
        color: #FF0000;
      }
    }
  }
}

.lot-permissions-tooltip {
  background: $black;
  border-radius: rem(3px);
  padding: rem(0 8px);

  ul {
    align-self: center;
    color: $white;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: rem(0 4px);
    width: 100%;
    font-size: rem(14px);

    li {
      width: 100%;
      padding: rem(2px 0);
    }
  }
}

// Variable overrides

@import '~react-md/src/scss/react-md';

$md-colors-warn-md: false;
$md-secondary-color: $secondary-color;
$md-primary-color: $primary-color;
$md-subheading-mobile-font-size: rem(16px);
$md-light-theme-background-color: $body-bg-color;
$md-list-desktop-font-size: rem(18px);
$md-font-name: 'AvenirNextLTW01-Regular';
$md-font-size-base: 16;
$md-body-desktop-font-size: rem(16px);
$md-light-theme-text-color: $secondary-text-color;
$md-tab-indicator-height: rem(4px);
$md-tab-max-width: rem(180px);
$md-light-theme-colors: (text: $md-light-theme-text-color, secondary: $md-light-theme-secondary-text-color, icon: $md-light-theme-icon-text-color, disabled: $md-light-theme-disabled-text-color, hint: $md-light-theme-hint-text-color, divider: $md-light-theme-divider-color, background: $md-light-theme-background-color, card: $md-light-theme-card-color, hover: $md-light-theme-divider-color, toolbar: $md-light-theme-app-bar-color);
$md-btn-text-border-radius: 3px;

// @include react-md-typography;
@include react-md-accessibility;

@include react-md-grid;
@include react-md-transitions;

// @include react-md-autocompletes;
@include react-md-avatars;

// @include react-md-badges;
// @include react-md-bottom-navigations;
@include react-md-buttons;
@include react-md-cards;

// @include react-md-chips;
// @include react-md-collapsers;
// @include react-md-data-tables;
@include react-md-dialogs;
@include react-md-dividers;
@include react-md-drawers;

// @include react-md-expansion-panels;
// @include react-md-file-inputs;
@include react-md-inks;
@include react-md-icons;
@include react-md-layovers;
@include react-md-lists;

// @include react-md-media;
@include react-md-menus;

// @include react-md-navigation-drawers;
@include react-md-overlays;
@include react-md-papers;

// @include react-md-pickers;
// @include react-md-progress;
// @include react-md-select-fields;
@include react-md-selection-controls;

// @include react-md-sliders;
// @include react-md-subheaders;
@include react-md-snackbars;
@include react-md-tabs;
@include react-md-text-fields;
@include react-md-toolbars;
@include react-md-tooltips;

@include react-md-helpers-everything;
@include react-md-colors;

// override react-md default media query
@media screen and (min-width: 1025px) {
  h5, h4 {
    font-size: rem(16px);
  }

  .md-btn--icon {
    height: rem(48px);
    padding: rem(12px);
    width: rem(48px);

    &.md-btn--floating {
      height: rem(64px);
      width: rem(64px);

      &.md-text--disabled {
        background-color: $disabled-color;
        color: $white;
      }
    }
  }

  .md-btn--text {
    height: rem(32px);
  }

  .md-icon,
  .material-icons.md-icon {
    font-size: rem(24px);
  }

  .md-tile-addon--icon {
    height: rem(24px);
  }
}

@media screen and (min-width: 320px) {
  h5, h4 {
    font-size: rem(16px);
  }

  .md-btn-text {
    height: rem(32px);
  }

  .md-btn--icon {
    height: rem(36px);
    width: rem(36px);
    padding: 0;

    &.md-btn--floating {
      height: rem(64px);
      width: rem(64px);
    }
  }

  .md-btn--text {
    height: rem(32px);
  }

  .md-tile-addon--icon {
    height: rem(24px);
  }
}

.md-icon {
  font-size: rem(24px);
}

.md-dialog {
  min-width: rem(510px);

  .md-title--dialog {
    font-family: $font-demi;
    font-size: rem(22px);
    margin-top: 0;
  }

  p {
    margin: 0;
  }

  .md-dialog-footer {
    padding: rem(24px);

    button {
      min-width: rem(220px);
    }
  }

  .md-dialog-footer--inline .md-btn--dialog + .md-btn--dialog {
    margin-left: rem(24px);
  }
}

.md-selection-control-toggle {
  color: $primary-color;
}

// react-md-list
.md-list {
  background: $body-bg-color;
}

.md-tile-content--left-icon {
  padding-left: rem(24px);
}

.md-selection-control-container {
  height: auto;
}

.md-list-tile {
  height: rem(48px);
}

.md-list-item {
  background-color: $white;
  border-radius: rem(4px);
  margin-bottom: rem(8px);

  &:last-child {
    margin-bottom: 0;
  }

  .md-tile-text--primary {
    color: $black;
    font-family: $font-demi;
    font-size: rem(24px);
  }
}

.md-list-control {

  .md-btn--icon {
    padding: 8px;
  }
}

@import '../../scss/imports';

.footer {
  background-color: #F7F7F7;
  border-top: 1px solid #E0E0E0;
  bottom: 0;
  height: rem(64px);
  left: 0;
  position: fixed;
  width: 100%;

  .footer-actions {
    height: rem(64px);

    button {
      & + button {
        margin-left: rem(16px) !important;
      }
    }
  }
}

@import "../../scss/imports";

.event-card-container {
  border-radius: $border-radius;
  margin: rem(0 0 16px 0);
}

.event-card {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  color: $secondary-text-color;
  cursor: pointer;
  margin: rem(0 auto 16px auto);
  max-height: rem(321px);
  max-width: rem(284px);
  overflow: hidden;

  &:hover {
    background-color: darken($white, 10%);
    transition: background-color 200ms ease-in;
  }

  &.selected {
    background-color: rgba(#2196F3, 0.11);
    box-shadow: rem(0 0 0 3px) #2196F3;
  }

  .check-button {
    border-color: rgba(0, 0, 0, 0.5);
    right: rem(12px);
    top: rem(12px);
    position: absolute;
    z-index: 1;

    &.background {
      color: #FFF;
    }

    &.white {
      color: rgba(255, 255, 255, 0.65);
    }

    &.hidden {
      opacity: 0;
    }
  }

  .image-background {
    height: rem(160px);
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 0;
  }

  .event-card-text {
    height: rem(160px);

    .event-details {
      display: flex;
      flex-direction: column;
      height: rem(80px);
      justify-content: space-between;
      padding: 0;

      h5 {
        margin: 0;
      }
    }
  }

  h5 {
    align-items: center;
    color: #101010;
    display: flex;

    i {
      font-size: rem(20px);
      margin-bottom: rem(2px);
      margin-right: rem(5px);
    }

    .green {
      color: #AED581;
    }
  }

  .blue {
    color: $primary-color;
  }

  .event-image-container {
    position: relative;

    img {
      overflow: hidden;
      width: 100%;
    }
  }

  .md-card-text {
    padding: rem(12px 16px 16px 16px) ;
  }

  .event-title {
    margin: 0 auto rem(10px) auto;
    min-height: rem(44px);
    position: relative;

    .material-icons {
      color: $primary-color;
      left: 0;
      top: 0;
    }

    h5 {
      font-family: $font-demi;
      font-size: rem(18px);
      margin: 0;
    }
  }
}

@import '../../scss/imports';

.no-show {
  align-items: center;
  display: flex;
  flex-direction: column;
  
  img {
    margin-top: rem(106px);
  }

  p {
    color: #9D9D9D;
    font-size: rem(22px);
    font-family: $font-regular;
  }
}

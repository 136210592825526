@import '../../scss/imports';

.event-tiles {
  margin-bottom: rem(84px);
}

#snackbar {
  background-color: #323232;
  box-shadow: rem(0px) rem(4px) rem(4px) rgba(0, 0, 0, 0.2);
  min-height: rem(56px);
  width: rem(786px);
  margin-bottom: 72px;

  p {
    margin: auto rem(10px);
  }

  button {
    color:  #7C4DFF;
    font-weight: bold;
  }
}

@import '../../scss/imports';

.vips-list-actionbar {
  align-items: center;
  display: flex;
  height: rem(32px);
  justify-content: space-between;
  width: 100%;

  #switch-duplicate-label {
    font-size: rem(14px);
  }

  .duplicate-switch {
    .md-switch-track {
      margin-left: 0;
    }

    .toggled {
      color: $primary-color;

      .md-switch-thumb {
        background: #2196F3;
      }

      .md-switch-track {
        background: rgba(33, 150, 243, .5);
      }
    }

    .untoggled {
      color: $black;
    }
  }
}

* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

html,
body {
  color: $text-color;
  font-family: $font-regular;
  font-size: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

main {
  padding-bottom: rem(40px);
}

h1, h2, h3, h4, h5, h6, p, button, input, textarea, html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #DEDEDE;
  margin-bottom: rem(24px);
  margin-top: rem(16px);
}

button,
input {
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background: #EAEAEA;
    color: #A8A8A8;
  }
}

button {
  font-family: $font-demi;
}

.button {
  margin: 0;

  &.ghost {
    border: 1.5px solid $black;
    color: $black;
  }

  &.blue-ghost {
    border: 1.5px solid $primary-color;
    color: $primary-color;
  }

  &.gray {
    background-color: #F4F4F4;
    color: #A3A3A3;
    font-family: $font-demi;
    font-size: rem(16px);
  }

  &.red {
    background: #F44336;
    color: $white;
  }

  &[disabled] {
    background: #EAEAEA;
    color: #A8A8A8;

    &.ghost,
    &.blue-ghost {
      border: 1.5px solid #EAEAEA;
    }
  }
}

.wrapper {
  max-width: $wrapper;
  padding: rem(0 16px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.wrapper-full {
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.md-tab-indicator {
  background: $white;
  transition-duration: initial;
  transition-timing-function: initial;
  min-height: rem(4px);
}

.icon-button {
  height: rem(36px);
  padding: 0;
  width: rem(36px);
  border: none;

  .md-icon {
    font-size: rem(24px);
    vertical-align: middle;
  }

  &.black {
    color: $secondary-text-color;
  }

  &.white {
    color: $white;
  }

  & + .icon-button {
    margin-left: rem(16px);
  }
}

.md-avatar {
  border: none;
}

.user-avatar {
  align-items: center;
  background-color: #26C6DA;
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: rem(14px);
  height: rem(32px);
  justify-content: center;
  width: rem(32px);
}

.page-title-separator {
  border-bottom: 1px #dedede solid;
  margin-bottom: rem(20px);
  padding-bottom: rem(20px);
}

.error-text {
  color: $priority-color;
}

.action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;

  button + button {
    margin-left: rem(16px);
  }
}

@import '../../scss/imports';

.top-action-bar {
  display: flex;
  margin-bottom: rem(20px);
  padding: 0;
  z-index: 1;

  .md-icon-text {
    padding-left: rem(8px);
  }

  .action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    button + button {
      margin-left: rem(16px);
    }
  }

  .search-input-container {
    margin-right: rem(8px);
  }

  .search-filter {
    display: flex;
    flex-direction: row;

    input {
      width: rem(180px);
    }
  }

  .list-view {
    font-size: rem(13px);
    margin: 0;
  }

  .add-item-button-label {
    margin-left: rem(10px);
  }
}
